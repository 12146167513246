.cover-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.genspace-cover {
    width: 85%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 768px) {
    .cover {
        margin-top: 12%;
    }

    .cover-wrapper {
        height: 95vh;
    }
    .genspace-cover {
        width: 98%;
        content: url("https://rihanbucket.s3.us-east-1.amazonaws.com/genspace-ai/genspace-ai-phone.webp");
    }
}
