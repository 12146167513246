.nav-bar {
    height: 50px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    margin: auto;
    top: 1%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99;
    padding: 14px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.268);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(5px);
}
.nav-bar-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    background-color: rgba(255, 255, 255, 0.268);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 30px;
    padding: 7px 12px;
    transition: 0.1s ease-in;
}

.ai {
    color: red;
}

.nav-bar-link:hover {
    color: rgb(16, 16, 16);
    background-color: rgb(204, 204, 204);
}

.navbar-logo {
    height: 30px;
    aspect-ratio: 1/1;
    cursor: pointer;
}

.navbar-logo img {
    width: 100%;
    height: 100%;
}

/* Hide menu toggle on large screens */
.menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 2.5rem;
    color: black;
}

/* Default navigation links */
.nav-links {
    display: flex;
    gap: 15px;
}

.nav-bar-link.active {
    background-color: #002fa7;
    color: white;
}

.material-symbols-outlined {
    font-size: 45px; /* Adjust size */
    color: #000000; /* Adjust color */
    vertical-align: middle;
    transition: all 1s ease-out;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .nav-bar {
        width: 95%;
        padding: 0;
        border-radius: 0;
    }

    .menu-toggle {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-bar-link {
        font-size: 1.15rem;
    }

    .navbar-logo {
        height: 40px;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 250px;
        top: 60px;
        right: 1%;
        background: rgb(255, 255, 255);
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .nav-links.open {
        display: flex;
    }
}
